h3 {
  padding-top: 20px;
}

ul.privacy li {
  list-style-type: disc;
  margin-left: 30px;
}

ul.privacy-list-embed li {
  list-style-type: disc;
  margin-left: 60px;
}