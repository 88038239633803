.form-background {
  background: url("../images/sign-in-background.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.sign-up-background {
  width: 100%;
  border-radius: 30px 0 0 30px;
  padding: 30px;
  color: #FFFFFF;
}

.heading-copy-white {
  font-size: 30px;
  font-weight: normal;
}

.body-copy-white {
  font-size: 18px;
  font-weight: normal;
}

.sign-up {
  display: flex;
  background: #ffffff;
  box-shadow: 0 11px 25px 0 rgba(0,0,0,0.15);
  border-radius: 42px;
  margin-top: 0;
}

.round-form {
  padding: 10px 23px;
  width: 100%;
}

.round-form-button {
  padding-top: 4px;
}

.round-form-heading {
  padding-bottom: 20px;
  font-size: 30px;
  line-height: 30px;
  padding-top: 0;
  margin: 11px 0 0;
  text-align: center;
  color: #1194F6;
  font-weight: normal;
}

.trial {
  color: #7E848D;
  font-size: .9rem;
  text-align: center;
  padding-top: 4px;
}

.form-content {
  margin-top: 20px;
}

.center-align {
  text-align: center;
  display: block;
}

.center-align .logo {
  margin-bottom: 40px;
}

.form-text {
  text-align: center;
  display: block;
  font-size: .9rem;
  padding-top: 7px;
  color: #7E848D;
}

.create-account {
  color: #202B3B;
}

.password-trouble {
  padding-top: 38px;
  font-size: .8rem;
}

.steps {
  padding: 30px 0 60px 0;
  text-align: left;
}

.app-store {
  padding: 20px 0 20px 0;
}

.review-settings {
  padding: 10px 0 20px 0;
}

.sub-heading {
  padding: 10px 0;
}

.loading {
  width: 200px;
  height: 200px;
  position: absolute;
  top: 50%;
  left: 50%;
}

.note {
  display: block;
  font-size: .8rem;
}

.sign-up-background img {
  display: none;
}

@media (min-width: 768px) {
  .sign-up {
    margin-top: 30px;
  }
  .sign-up-background img {
    display: block;
  }
}