.error {
  margin: 0 0 24px;
  padding: 15px;
  color: #f47369;
  background: #fde3e1;
  border-left: 6px solid #f47369;
  border-radius: 10px;
  font-size: .9rem;
}

.success {
  margin: 0 0 24px;
  padding: 15px;
  color: #3ADB7E;
  background: #E7FAE8;
  border-left: 6px solid #3ADB7E;
  border-radius: 10px;
  font-size: .9rem;
}