.PostEditModal {
    position: absolute;
    top: 100%;
    left: 50%;
    right: auto;
    bottom: auto;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    background-color: white;
    outline: none;
    z-index: 100;
}

@media (min-width: 720px) {
    .PostEditModal {
        width: 1200px;
        height: 2000px;
    }
}

.PostEditOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(52,58,63,0.60);
    z-index: 100;
    overflow: scroll;
}

/* fix so background window doesn't scroll */
.ReactModal__Body--open {
    overflow: hidden;
    /*Removed these, they made the background jump, not sure if they are needed for some browsers*/
    /*position: fixed;*/
    /*width: 100%;*/
    /*height: 100%;*/
}
